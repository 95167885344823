document.addEventListener('DOMContentLoaded', function () {

    $('.tab').on('click', function (event) {
        event.preventDefault();

        $($(this).siblings()).removeClass('tab-active');
        $($(this).closest('.tabs-wrapper').siblings().find('div')).removeClass('tabs-content-active');

        $(this).addClass('tab-active');
        $($(this).attr('href')).addClass('tabs-content-active');
    });

    ismobile = false;

    if (window.innerWidth < 1024) {
        ismobile = true;
    }


    const initSmoothAppearance = (selector, animationOptions = {}) => {
        const defaultOptions = {
            opacity: 0,
            duration: 1,
            ease: 'power2.out',
        };

        ismobile ? defaultOptions.y = -50 : defaultOptions.y = -100;

        const options = { ...defaultOptions, ...animationOptions };

        gsap.utils.toArray(selector).forEach((element, index) => {
            const staggerDelay = index * (animationOptions.stagger || 0);

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: element,
                    start: `top ${ismobile ? '65%' : '85%'}`,
                },
            });

            tl.from(element, {
                delay: staggerDelay,
                ...options,
                x: options.x || 0,
                duration: options.duration || 1,
            });
        });
    };

    initSmoothAppearance('.promo h1 span', { duration: 1, y: 50, opacity: 0, stagger: 0.3 });
    initSmoothAppearance('.advantages-item', { duration: 1, y: -50, opacity: 0, stagger: 0.3 });
    initSmoothAppearance('.tabs-content', { duration: 1, y: -30, opacity: 0, });
    initSmoothAppearance('.markers-wrapper .item', { duration: 1, y: -50, opacity: 0, stagger: 0.1 });
    initSmoothAppearance('.form__anim', { duration: 1, y: -50, opacity: 0, stagger: 0.1 });

});