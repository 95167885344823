

const validation = new JustValidate('.bottom-form');
const doneMessage = document.querySelector('.bottom-form__done');

validation
    .addField('.input-name', [
        {
            rule: 'minLength',
            value: 3,
        },
        {
            rule: 'maxLength',
            value: 30,
        },
        {
            rule: 'required',
            value: true,
            errorMessage: 'Введіть імя!'
        }
    ])
    .addField('.input-mail', [
        {
            rule: 'required',
            value: true,
            errorMessage: 'Необхідно вказати адресу електронної пошти',
        },
        {
            rule: 'email',
            value: true,
            errorMessage: 'Будь ласка, введіть дійсну адресу електронної пошти',
        },
    ])
    .addField('.input-tel', [
        {
            rule: 'required',
            value: true,
            errorMessage: 'Потрібен телефон',
        },
    ]).onSuccess((event) => {

        let formData = new FormData(event.target);
        let xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    doneMessage.style.display = 'block';
                    setTimeout(() => {
                        doneMessage.style.display = 'none';
                    }, 3000);
                }
            }
        };

        xhr.open('POST', 'mail.php', true);
        xhr.send(formData);

        event.target.reset();
    });




